import { DeviceData } from "src/entities.ts";
import { getDeviceIcon } from "src/helpers.ts";

export interface DeviceProps {
  device: DeviceData;
}

export default function Device({ device }: DeviceProps) {
  const isOnline = device.isConnected || device.isResponding;

  const className = device.ip && isOnline ? (device.isResponding ? "online" : "not-responding") : isOnline ? "online" : "offline";
  const statusMessage = device.ip && isOnline ? (device.isResponding ? "Online" : "Neodpovídá") : isOnline ? "Online" : "Offline";

  return (
    <div class="device">
      <div class="device-icon">
        <i class={`fas ${getDeviceIcon(device.type)}`} />
      </div>
      <h3>{device.label}</h3>
      <p class={`status ${className}`}>{statusMessage}</p>
      <div class="device-details">
        {device.ip && (
          <p>
            <strong>IP:</strong> {device.ip}
            {device.wifiBand && <span class="wifi-band"> ({device.wifiBand})</span>}
          </p>
        )}
        {device.mac && (
          <p>
            <strong>MAC:</strong> {device.mac}
          </p>
        )}
        {device.ip && (
          <p>
            <strong>Is responding:</strong> {device.isResponding ? "Yes" : "No"}
          </p>
        )}
        <p>
          <strong>Is leasing DHCP:</strong> {device.leaseDhcp ? "Yes" : "No"}
        </p>
      </div>
      <p class="last-updated">Last updated: {new Date(device.lastCheckedAt).toLocaleTimeString("cs-CZ")}</p>
    </div>
  );
}
