import { useEffect, useRef, useState } from "preact/hooks";
import Controls from "src/components/Controls.tsx";
import Device from "src/components/Device.tsx";
import { NetworkData } from "src/entities.ts";
import { networksAtom } from "src/components/Dashboard.tsx";
import StatusMessage from "src/components/StatusMessage.tsx";
import { useAtom } from "jotai";

export interface NetworkProps {
  network: NetworkData;
}

export default function Network({ network }: NetworkProps) {
  const [statusMessage, setStatusMessage] = useState<string | null>("");
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [isAutocheckEnabled, setIsAutocheckEnabled] = useState<boolean>(false);
  const [isSettingAutocheck, setIsSettingAutocheck] = useState<boolean>(false);
  const [, setNetworks] = useAtom(networksAtom);
  const isMounted = useRef(false);

  const handleManualCheck = async () => {
    console.log("Manual check");

    setIsChecking(true);

    try {
      await network.client.check();
      const devices = await network.client.getDevices();

      setNetworks((prev) => prev.map((n) => (n.label === network.label ? { ...network, devices } : n)));
      setStatusMessage(null);
    } catch (err) {
      setStatusMessage(`Error fetching network data for ${network.label}: ${(err as Error).message}`);
    } finally {
      setIsChecking(false);
    }
  };

  const handleStartAutoCheck = async () => {
    console.log("Start auto check");

    setIsSettingAutocheck(true);

    try {
      await network.client.start();
      const node = await network.client.getNode();

      setNetworks((prev) => prev.map((n) => (n.label === network.label ? { ...network, node } : n)));
      setIsAutocheckEnabled(true);
      setStatusMessage(null);
    } catch (err) {
      setStatusMessage(`Error enabling auto check for ${network.label}: ${(err as Error).message}`);
    } finally {
      setIsSettingAutocheck(false);
    }
  };

  const handleStopAutoCheck = async () => {
    console.log("Stop auto check");

    setIsSettingAutocheck(true);

    try {
      await network.client.stop();
      const node = await network.client.getNode();

      setNetworks((prev) => prev.map((n) => (n.label === network.label ? { ...network, node } : n)));
      setIsAutocheckEnabled(false);
      setStatusMessage(null);
    } catch (err) {
      setStatusMessage(`Error disabling auto check for ${network.label}: ${(err as Error).message}`);
    } finally {
      setIsSettingAutocheck(false);
    }
  };

  useEffect(() => {
    const runEffect = async () => {

      try {
        const devices = await network.client.getDevices();
        const node = await network.client.getNode();

        setNetworks((prev) => prev.map((n) => (n.label === network.label ? { ...network, devices, node } : n)));
        setIsAutocheckEnabled(node.isTimerEnabled);
        setStatusMessage(null);
      } catch (err) {
        setStatusMessage(`Error fetching network data for ${network.label}: ${(err as Error).message}`);
      }
    };

    if (!isMounted.current) {
      runEffect();

      isMounted.current = true;
    }
  }, [network, setNetworks, setStatusMessage, setIsChecking, setIsAutocheckEnabled]);

  return (
    <div class="network">
      <StatusMessage statusMessage={statusMessage} />

      <Controls
        onManualCheck={handleManualCheck}
        onStartAutoCheck={handleStartAutoCheck}
        onStopAutoCheck={handleStopAutoCheck}
        isChecking={isChecking}
        isAutocheckEnabled={isAutocheckEnabled}
        isSettingAutocheck={isSettingAutocheck}
      />

      <h2>{network.label}</h2>
      <div class="device-grid">{network.devices?.map((device) => <Device key={device.label} device={device} />)}</div>
    </div>
  );
}
