import { atom, useAtom } from "jotai";
import Network from "src/components/Network";
import { NetworkData } from "src/entities.ts";
import { getInitialNetworks } from "src/helpers.ts";

import "src/styles/dashboard.scss";

export const networksAtom = atom<NetworkData[]>(getInitialNetworks());

export default function Dashboard() {
  const [networks] = useAtom(networksAtom);

  return (
    <div>
      <h1>Device Status Dashboard</h1>
      <div id="networks">{networks?.map((network: NetworkData) => <Network key={network.label} network={network} />)}</div>
    </div>
  );
}
