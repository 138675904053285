import { DeviceData, NodeData } from "src/entities.ts";

export default class ApiClient {
  private baseUrl: string;
  private key: string;

  constructor(baseUrl: string, key: string) {
    this.baseUrl = baseUrl;
    this.key = key;
  }

  async getDevices() {
    const res = await fetch(`${this.baseUrl}/api/devices`, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: this.key
      }
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch devices. Status: ${res.status}`);
    }

    return (await res.json()) as DeviceData[];
  }

  async getNode() {
    const res = await fetch(`${this.baseUrl}/api/node`, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: this.key
      }
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch node. Status: ${res.status}`);
    }

    return (await res.json()) as NodeData;
  }

  async check() {
    const res = await fetch(`${this.baseUrl}/api/check`, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: this.key
      }
    });

    if (!res.ok) {
      throw new Error(`Failed to check. Status: ${res.status}`);
    }
  }

  async start() {
    const res = await fetch(`${this.baseUrl}/api/start`, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: this.key
      }
    });

    if (!res.ok) {
      throw new Error(`Failed to start. Status: ${res.status}`);
    }
  }

  async stop() {
    const res = await fetch(`${this.baseUrl}/api/stop`, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: this.key
      }
    });

    if (!res.ok) {
      throw new Error(`Failed to stop. Status: ${res.status}`);
    }
  }
}
