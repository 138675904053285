interface ControlsProps {
  onManualCheck: () => Promise<void>;
  onStartAutoCheck: () => Promise<void>;
  onStopAutoCheck: () => Promise<void>;
  isChecking: boolean;
  isAutocheckEnabled: boolean;
  isSettingAutocheck: boolean;
}

export default function Controls({ onManualCheck, onStartAutoCheck, onStopAutoCheck, isChecking, isAutocheckEnabled, isSettingAutocheck }: ControlsProps) {
  return (
    <div class="controls">
      <button onClick={onManualCheck} class="btn btn-primary" disabled={isChecking} aria-label="Manual Check">
        {isChecking ? "Checking..." : "Manual Check"}
      </button>

      <div class="nested-controls">
        <button onClick={onStartAutoCheck} class="btn btn-success" disabled={isAutocheckEnabled || isSettingAutocheck} aria-label="Enable Auto Check">
          Enable Auto Check
        </button>
        <button onClick={onStopAutoCheck} class="btn btn-danger" disabled={!isAutocheckEnabled || isSettingAutocheck} aria-label="Disable Auto Check">
          Disable Auto Check
        </button>
      </div>
    </div>
  );
}
