import { NetworkData } from "src/entities.ts";
import ApiClient from "src/services/api.ts";

export function getDeviceIcon(type: string) {
  switch (type) {
    case "computer":
      return "fa-desktop";
    case "tv":
      return "fa-tv";
    case "phone":
      return "fa-mobile-alt";
    case "printer":
      return "fa-print";
    case "server":
      return "fa-server";
    default:
      return "fa-network-wired";
  }
}

export function getInitialNetworks() {
  const initialNetworks: NetworkData[] = [];

  for (const endpoint of import.meta.env.VITE_ENDPOINTS.split(",")) {
    const [label, baseUrl] = endpoint.split("|");
    const client = new ApiClient(baseUrl, import.meta.env.VITE_API_KEY);

    const network: NetworkData = {
      label,
      client,
      devices: [],
      node: null
    };

    initialNetworks.push(network);
  }

  return initialNetworks;
}
